import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import ExpanderList from '@helsenorge/designsystem-react/components/ExpanderList';
import LazyIcon from '@helsenorge/designsystem-react/components/LazyIcon';
import LinkList from '@helsenorge/designsystem-react/components/LinkList';
import Spacer from '@helsenorge/designsystem-react/components/Spacer';
import Title from '@helsenorge/designsystem-react/components/Title';
import { trackNavigation } from '@helsenorge/framework-utils/adobe-analytics';
import { getAbsoluteUrl } from '../shared/links';
import styles from './styles.module.scss';
const TemaMenuV2 = (props) => {
    if (!props.menuItems || props.menuItems.length < 1) {
        return null;
    }
    return (_jsx(ExpanderList, { large: true, color: "cherry", children: props.menuItems.map(menuItem => (_jsx(ExpanderList.Expander, { large: true, onExpand: (isExpanded) => {
                if (isExpanded) {
                    trackNavigation('toppmeny', menuItem.menuTitle, 'sidetopp');
                }
            }, title: _jsx(Title, { htmlMarkup: 'span', appearance: 'title2', children: menuItem.menuTitle }), children: _jsxs("div", { className: styles.temamenu__expander, children: [menuItem.subMenuItems && menuItem.subMenuItems.length > 0 && _jsx(TemaMenuV2, { menuItems: menuItem.subMenuItems }), menuItem.linkGroupsList.length > 0 &&
                        menuItem.linkGroupsList.map((menuExpanderlist, i) => (_jsxs("div", { children: [menuExpanderlist.groupHeading && (_jsxs(_Fragment, { children: [_jsx(Title, { htmlMarkup: 'h3', appearance: 'title5', children: menuExpanderlist.groupHeading }), _jsx(Spacer, {})] })), menuExpanderlist.linksList.length > 0 && (_jsx(LinkList, { chevron: true, children: menuExpanderlist.linksList.map(link => (_jsx(LinkList.Link, { href: getAbsoluteUrl(link), title: link.title ?? undefined, onClick: () => trackNavigation('toppmeny', `${menuItem.menuTitle}: ${link.text}`, 'sidetopp'), icon: menuExpanderlist.showIcons && link.icon ? _jsx(LazyIcon, { iconName: link.icon }) : undefined, children: link.text }, link.text))) }))] }, i)))] }) }, menuItem.menuTitle))) }));
};
export default TemaMenuV2;
