import { useContext, useEffect } from 'react';

import LinkList, { LinkAnchorTargets } from '@helsenorge/designsystem-react/components/LinkList';
import Loader from '@helsenorge/designsystem-react/components/Loader';
import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';

import { mapCultureNameToLanguageLocale } from '@helsenorge/core-cms/shared/language';
import { getAbsoluteUrl } from '@helsenorge/core-cms/shared/links';
import TemaMenu from '@helsenorge/core-cms/temamenu';
import TemaMenuV2 from '@helsenorge/core-cms/temamenu-v2';
import { LinkDTO } from '@helsenorge/core-cms/types/entities/cms.v1entities';
import { Breakpoint, useBreakpoint } from '@helsenorge/designsystem-react';
import { trackNavigation } from '@helsenorge/framework-utils/adobe-analytics';
import getFeatureToggle from '@helsenorge/framework-utils/hn-feature-toggle';
import { setUserLanguage } from '@helsenorge/framework-utils/hn-language';

import { globalStateContext } from '../../store';
import MenuSearch from '../menu-search';

import styles from './styles.module.scss';

function moveLazyLoadedStylingToWebComponent(): void {
  const vertikalAssetsPath = 'header-footer';
  const localhostFileName = 'static/css/src_components_menu-basic_menu-basic_tsx.css';
  const webComponentName = 'hn-webcomp-header';

  document.querySelectorAll('head link').forEach(link => {
    if ((link as HTMLLinkElement).href.includes(vertikalAssetsPath) || (link as HTMLLinkElement).href.includes(localhostFileName)) {
      document.querySelector('head')?.removeChild(link);
      document.querySelector(webComponentName)?.shadowRoot?.appendChild(link);
    }
  });
}

const MenuBasic = (): React.JSX.Element => {
  const globalState = useContext(globalStateContext);
  const breakpoint = useBreakpoint();

  useEffect(() => {
    moveLazyLoadedStylingToWebComponent();
  }, []);

  const handleLinkClick = (link: LinkDTO): void => {
    if (link.text) {
      trackNavigation('toppmeny', link.text, 'sidetopp');
    }
    const locale = mapCultureNameToLanguageLocale(link.cultureName);
    if (locale) {
      setUserLanguage(locale);
    }
  };

  const showSearch = breakpoint < Breakpoint.md;

  return (
    <div className={styles['menu-basic']}>
      {globalState?.error?.headerFooterError && (
        <NotificationPanel fluid={true} variant={'error'}>
          {globalState?.error?.headerFooterError}
        </NotificationPanel>
      )}
      {showSearch && <MenuSearch />}
      {globalState.loading?.isHeaderFooterLoading ? (
        <Loader />
      ) : (
        globalState.headerFooter?.header.globalMenu.menuItems && (
          <div className={styles['menu-basic__temamenu']}>
            {getFeatureToggle('NyForside2025') ? (
              <TemaMenuV2 menuItems={globalState.headerFooter.header.globalMenu.menuItems} />
            ) : (
              <TemaMenu type="menu" menuItems={globalState.headerFooter.header.globalMenu.menuItems} />
            )}
          </div>
        )
      )}
      <div>
        <LinkList color="cherry" chevron={getFeatureToggle('NyForside2025')}>
          {globalState.headerFooter?.header.globalMenu.linkList?.map((link, index) => (
            <LinkList.Link
              key={index}
              href={getAbsoluteUrl(link)}
              target={(link.target as LinkAnchorTargets) ?? undefined}
              title={link.title ?? undefined}
              onClick={(): void => handleLinkClick(link)}
            >
              {link.text}
            </LinkList.Link>
          ))}
        </LinkList>
      </div>
    </div>
  );
};

export default MenuBasic;
